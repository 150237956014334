import {
    InteractionRequiredAuthError,
    type SilentRequest,
    type AccountInfo,
    type AuthenticationResult,
    type IPublicClientApplication,
    CacheLookupPolicy,
} from '@azure/msal-browser-1p';
import { AccountSourceType } from 'owa-account-source-list-types';
import { logStartCoreUsage, logStartUsage } from 'owa-analytics-start';
import type { MailboxInfo } from 'owa-client-types';
import { isUrlPresent, isHosted } from 'owa-config';
import type { HeadersWithoutIterator } from 'owa-service/lib/RequestOptions';
import type TokenResponse from 'owa-service/lib/contract/TokenResponse';
import type TokenResponseCode from 'owa-service/lib/contract/TokenResponseCode';
import { getTypeHintFromMailboxInfo } from './getTypeHint';
import { getMsalInstance, getClientId, getCacheLocation } from './initializeMsalLibrary';
import { loginUserMsalInternal } from './loginUserMsal';
import { onInteractionRequiredAuthFailure } from './onInteractionRequiredAuthFailure';
import { InteractionType } from './utils/InteractionType';
import { getAccountFromMsal } from './utils/MsalAccounts';
import { getAuthority } from './utils/getAuthority';
import { getScopes, isDefaultScope } from './utils/getScopes';
import { getLogAuthErrorData, getLogAuthResultData } from './utils/logger';
import { msalAuthenticationResultToTokenResponse } from './utils/msalAuthenticationResultToTokenResponse';
import getClaims from './utils/getClaims';
import getSilentRedirectUri from './utils/getSilentRedirectUri';
import { createAuthError } from '@azure/msal-common';
import { getGuid } from 'owa-guid';
import { getIsSigninRequired, setIsSigninRequired } from './utils/signinRequiredHelpers';
import { isMailboxSameAccountAsGlobalSettingsAccount } from 'owa-account-source-list-store';

/**
 * Request access token from MSAL for given resource url or scope. User should be logged in already before this API is called.
 * Support for interactive token acquisition is limited to CAE claim challenge remediation only. Until we support multi-account in OWA, user will be taken to the login flow if interactive auth is needed for primary account in OWA.
 */
export async function acquireAccessTokenMsal(
    mailboxInfo: MailboxInfo,
    resource?: string,
    scope?: string,
    headers?: HeadersWithoutIterator,
    correlationId?: string
): Promise<TokenResponse> {
    const typeHint = getTypeHintFromMailboxInfo(mailboxInfo);
    if (typeHint === AccountSourceType.Other) {
        return {
            TokenResultCode: 2,
            SubErrorCode: 'UnsupportedAccountType',
        };
    }

    correlationId = correlationId ?? getGuid();
    const msalInstance = await getMsalInstance();
    const account = getAccountFromMsal(msalInstance, mailboxInfo);
    const claims = headers && getClaims(headers);
    let authResultPromise: Promise<AuthenticationResult>;

    if (!account) {
        logStartUsage('Msal-AcquireToken-NoAccount', {
            accountType: typeHint,
            correlationId,
        });
        authResultPromise = loginUserMsalInternal(
            correlationId,
            msalInstance,
            InteractionType.Silent,
            typeHint,
            undefined /*account*/,
            mailboxInfo,
            undefined /*username*/,
            resource,
            scope,
            undefined /*promptValue*/,
            claims
        );
    } else {
        authResultPromise = acquireTokenSilently(
            correlationId,
            msalInstance,
            account,
            typeHint,
            resource,
            scope,
            claims
        );
    }

    return (
        authResultPromise
            // 1st-level error handler: check error from authResultPromise and perform CAE remediation if necessary
            .catch(async error => {
                if (error instanceof InteractionRequiredAuthError && claims) {
                    // Initiate interactive request for CAE claim challenge remediation
                    const interactionWithPopup = isHosted();
                    // Log the start of the interactive request, before popup is displayed or page redirects away.
                    logStartCoreUsage('Msal-AcquireToken', {
                        accountType: typeHint,
                        interactionType: interactionWithPopup
                            ? InteractionType.Popup
                            : InteractionType.Redirect,
                        isCAERemediation: true,
                        correlationId,
                        applicationId: getClientId(),
                        cacheLocation: getCacheLocation(),
                    });
                    if (interactionWithPopup) {
                        return msalInstance.acquireTokenPopup({
                            account: account ?? undefined,
                            scopes: getScopes(typeHint, resource, scope),
                            authority: getAuthority(typeHint),
                            redirectUri: getSilentRedirectUri(),
                            claims,
                            correlationId,
                        });
                    } else {
                        await msalInstance.acquireTokenRedirect({
                            account: account ?? undefined,
                            scopes: getScopes(typeHint, resource, scope),
                            authority: getAuthority(typeHint),
                            claims,
                            correlationId,
                        });
                        throw createAuthError('NotRedirected'); // make TS happy
                    }
                }

                throw error;
            })
            .then(authResult => msalAuthenticationResultToTokenResponse(authResult, typeHint))
            // 2nd-level error handler: catch-all errors from CAE remediation flow or unhandled from authResultPromise
            .catch(error => {
                if (
                    error instanceof InteractionRequiredAuthError &&
                    isDefaultScope(typeHint, resource, scope) &&
                    isMailboxSameAccountAsGlobalSettingsAccount(mailboxInfo)
                ) {
                    if (!getIsSigninRequired()) {
                        // Log the event to track the start of the remediation flow
                        logStartCoreUsage('Msal-AcquireToken', {
                            accountType: typeHint,
                            correlationId,
                            applicationId: getClientId(),
                            cacheLocation: getCacheLocation(),
                            isSigninRequired: true,
                        });
                    }

                    // Display sign in banner for other interaction required errors
                    setIsSigninRequired(true);
                    onInteractionRequiredAuthFailure();
                }

                return { TokenResultCode: 2, SubErrorCode: error.errorCode };
            })
    );
}

export async function acquireTokenSilently(
    correlationId: string,
    msalInstance: IPublicClientApplication,
    account: AccountInfo,
    typeHint: AccountSourceType,
    resource?: string,
    scope?: string,
    claims?: string
): Promise<AuthenticationResult> {
    const startTime = self.performance.now();

    const request: SilentRequest = {
        account,
        scopes: getScopes(typeHint, resource, scope),
        authority: getAuthority(typeHint),
        redirectUri: getSilentRedirectUri(),
        claims,
        correlationId,

        // msal-browser@3.26.1 has a regression in NAA where cacheLookupPolicy is not properly defaulted to
        // CacheLookupPolicy.Default. Explicitly pass Default here as a workaround until MSAL fix is in.
        cacheLookupPolicy: CacheLookupPolicy.Default,
    };

    let pageAlwaysVisible = self.document.visibilityState === 'visible';
    const visibilityListener = () => {
        if (self.document.visibilityState != 'visible') {
            pageAlwaysVisible = false;
        }
    };
    self.document.addEventListener('visibilitychange', visibilityListener);

    try {
        const authResult = await msalInstance.acquireTokenSilent(request);

        self.document.removeEventListener('visibilitychange', visibilityListener);

        logStartCoreUsage(
            'Msal-AcquireToken',
            getLogAuthResultData(
                InteractionType.Silent,
                authResult,
                startTime,
                typeHint,
                !!request.authority && isUrlPresent(request.authority),
                pageAlwaysVisible,
                request.scopes.join(' ')
            )
        );
        return authResult;
    } catch (e) {
        const isCAERemediationRequired = e instanceof InteractionRequiredAuthError && !!claims;
        self.document.removeEventListener('visibilitychange', visibilityListener);

        logStartCoreUsage(
            'Msal-AcquireToken',
            getLogAuthErrorData(
                InteractionType.Silent,
                e,
                startTime,
                request.scopes,
                typeHint,
                !!request.authority && isUrlPresent(request.authority),
                isCAERemediationRequired,
                pageAlwaysVisible
            )
        );
        throw e;
    }
}
