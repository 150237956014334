import { getItem, setItem, removeItem } from 'owa-local-storage';
import { getTypeHint } from './getTypeHint';
import { AccountSourceType } from 'owa-account-source-list-types';
import { isHosted } from 'owa-config';

/*
These set of methods tell apps like FindTime/Bookings & BookWithMe whether a session is anonymous or authenticated
*/

export function isAnonymousSession(): boolean {
    return !isHosted() && !getIsAuthenticated();
}

function getIsAuthenticated(): boolean {
    return !!getItem(self, 'isauthed');
}

export function setIsAuthenticated() {
    if (getTypeHint() === AccountSourceType.Office365) {
        setItem(self, 'isauthed', '1');
    }
}

export function removeIsAuthenticated() {
    if (getTypeHint() === AccountSourceType.Office365) {
        removeItem(self, 'isauthed');
    }
}
