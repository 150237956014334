import { AccountSourceType } from 'owa-account-source-list-types';
import { findMetatag } from 'owa-metatags';
import { MSA_DEFAULT_SCOPE } from './constants';

export function getScopes(
    typeHint: AccountSourceType,
    resource?: string,
    scope?: string
): string[] {
    if (typeHint === AccountSourceType.Office365) {
        // For enterprise requests, expect resource parameter, or default to EXO resource.
        const resourceUrl = resource ?? findMetatag('publicUrl');

        if (resourceUrl) {
            if (resourceUrl.toLowerCase().endsWith('/.default')) {
                return [resourceUrl];
            } else {
                // remove any existing trailing '/', before appending the /.default scope
                return [`${resourceUrl.replace(/\/+$/, '')}/.default`];
            }
        }
    } else if (typeHint === AccountSourceType.OutlookDotCom) {
        // For consumer requests, expect scope parameter and ignore resource.
        // If scope is not available, use the default MSA scope.
        return [scope ?? MSA_DEFAULT_SCOPE];
    }

    return [];
}

export function isDefaultScope(
    typeHint: AccountSourceType,
    resource?: string,
    scope?: string
): boolean {
    const owaPublicUrl = (findMetatag('publicUrl') || '').toLowerCase();
    const scopes = getScopes(typeHint, resource, scope);
    return scopes.includes(`${owaPublicUrl}/.default`) || scopes.includes(MSA_DEFAULT_SCOPE);
}
