import type { MailboxInfo } from 'owa-client-types';
import { getMsalInstance } from '../initializeMsalLibrary';
import {
    type AuthenticationResult,
    type SilentRequest,
    type PopupRequest,
    type IPublicClientApplication,
    InteractionRequiredAuthError,
    CacheLookupPolicy,
} from '@azure/msal-browser-1p';
import { getTypeHint } from '../getTypeHint';
import { getAuthority as commonGetAuthority } from '../utils/getAuthority';
import { AccountSourceType } from 'owa-account-source-list-types';
import { DC_QUERY_PARAM, OWA_MSAL_ENTERPRISE_PUBLIC_ORIGINS } from '../utils/constants';
import { isFeatureEnabled } from 'owa-feature-flags';
import {
    type AccountInfo,
    type StringDict,
    ClientAuthErrorCodes,
    createAuthError,
    createClientAuthError,
} from '@azure/msal-common';
import { getAccountScopeUserSettings } from 'owa-session-store/lib/selectors/getAccountScopeUserSettings';
import { getAccountFromMsal } from '../utils/MsalAccounts';
import { findMetatag } from 'owa-metatags';
import { isUrlPresent, isGulping } from 'owa-config';
import getSilentRedirectUri from '../utils/getSilentRedirectUri';
import { OwaApplicationId } from 'owa-service/lib/constants/ApplicationId';
import type { TokenRequest } from 'owa-nested-app-auth';
import { loginUserMsalInternal } from '../loginUserMsal';
import { InteractionType } from '../utils/InteractionType';
import { getGuid } from 'owa-guid';

const STS_CLIENT_ID = 'client_id';
const STS_BROKER_CLIENT_ID = 'brk_client_id';
const STS_BROKER_REDIRECT_URI = 'brk_redirect_uri';

function getNestedAppAuthRedirectUri(trustedOrigin: string) {
    // *important!* the origin must be an unspoofable value that is obtained by the caller
    // and then used here to assemble the redirect URI. Do not use any untrusted origin provided
    // by the child app itself -- always compute host-side using validated message origins
    return `brk-multihub://${trustedOrigin}`;
}

function getAuthority(
    typeHint: AccountSourceType,
    msalAccount?: AccountInfo,
    mailboxInfo?: MailboxInfo
): string {
    if (typeHint == AccountSourceType.Office365) {
        // find the base AAD authority URL from the OWS metatags -- in local dev scenarios, the value
        // may be missing, so fallback to the regular global one if not present
        const metatagAuthorityUrl = findMetatag('aadAuthorityUrl');
        const baseAuthorityUrl =
            metatagAuthorityUrl && isUrlPresent(metatagAuthorityUrl)
                ? metatagAuthorityUrl
                : isGulping()
                ? 'https://login.microsoftonline.com/'
                : undefined;

        // AAD token requests should go to the tenanted authority specific to this user if we can get it;
        // as otherwise token requests for single-tenant apps can fail against the common endpoint
        const tenantId = msalAccount
            ? msalAccount.tenantId
            : mailboxInfo
            ? getAccountScopeUserSettings(mailboxInfo).SessionSettings?.ExternalDirectoryTenantGuid
            : undefined;

        if (baseAuthorityUrl && tenantId) {
            return `${baseAuthorityUrl}${tenantId}`;
        }
    }

    return commonGetAuthority(typeHint);
}

function getBrokerExtraParameters(
    msalInstance: IPublicClientApplication,
    mailboxInfo: MailboxInfo,
    nestedClientId: string,
    isSelfBrokering: boolean
): StringDict {
    const msalConfig = msalInstance.getConfiguration();

    // crucially, for NAA or pairwise brokering, the client_id becomes the id of the nested child app,
    // and OWA's own client ID and redirect URI are set as brk_client_id and brk_redirect_uri, respectively
    const extraParameters: StringDict = !isSelfBrokering
        ? {
              [STS_CLIENT_ID]: nestedClientId,
              [STS_BROKER_CLIENT_ID]: msalConfig.auth.clientId,
              [STS_BROKER_REDIRECT_URI]: getSilentRedirectUri(),
          }
        : {};

    if (
        isFeatureEnabled(
            'auth-msaljs-sendDcQueryParam',
            mailboxInfo /*mailboxInfo*/,
            true /*dontThrowErrorIfNotInitialized*/
        )
    ) {
        // use an STS test slice for this request
        extraParameters.dc = DC_QUERY_PARAM;
    }

    return extraParameters;
}

/**
 * Allow self-brokering (ie. OWA-hosts-OWA without a separate clientId) if the
 * incoming request is using the OWA app id and the trusted origin is one of the
 * public OWA URLs, otherwise self brokering is denied
 * @param trustedOrigin origin of the incoming NAA request
 * @returns true if self brokering
 */
function isSelfBrokeringRequest(
    msalInstance: IPublicClientApplication,
    trustedOrigin: string,
    nestedClientId: string
): boolean {
    // self-brokering only enabled for OWA-hosting-OWA using the standard OWA app ID
    if (
        nestedClientId !== OwaApplicationId ||
        msalInstance.getConfiguration().auth.clientId !== OwaApplicationId
    ) {
        return false;
    }

    // allow if the request came from one of the known OWA URLs
    if (OWA_MSAL_ENTERPRISE_PUBLIC_ORIGINS.some(re => re.test(trustedOrigin))) {
        return true;
    }

    // alternatively, for vanity/sovereign domains, from the domain in the metatag
    const businessCanonicalHostName = findMetatag('businessCanonicalHostName');
    return businessCanonicalHostName != undefined && trustedOrigin === businessCanonicalHostName;
}

function getBrokerBaseRequestParameters(
    msalInstance: IPublicClientApplication,
    typeHint: AccountSourceType,
    mailboxInfo: MailboxInfo,
    trustedOrigin: string,
    nestedClientId: string,
    scopes: string[],
    msalAccount: AccountInfo,
    correlationId: string,
    authority?: string,
    claims?: string
): SilentRequest {
    const isSelfBrokering = isSelfBrokeringRequest(msalInstance, trustedOrigin, nestedClientId);
    const extraQueryParameters = getBrokerExtraParameters(
        msalInstance,
        mailboxInfo,
        nestedClientId,
        isSelfBrokering
    );

    return {
        authority: authority ?? getAuthority(typeHint, msalAccount, mailboxInfo),
        redirectUri: !isSelfBrokering
            ? getNestedAppAuthRedirectUri(trustedOrigin)
            : getSilentRedirectUri(),
        scopes,
        claims,
        correlationId,
        extraQueryParameters,
        tokenBodyParameters: { ...extraQueryParameters }, // needs to be a copy
        storeInCache: {
            idToken: isSelfBrokering, // brokered access+id tokens for children should not be cached by the host
            accessToken: isSelfBrokering,
        },
        cacheLookupPolicy: !isSelfBrokering
            ? CacheLookupPolicy.RefreshTokenAndNetwork // skip cache lookup for brokered access tokens - not implemented in MSAL
            : undefined,
    };
}

export async function acquireBrokeredAccessTokenMsal(
    mailboxInfo: MailboxInfo,
    trustedOrigin: string,
    naaRequest: TokenRequest,
    silent: boolean,
    targetWindow?: Window
): Promise<AuthenticationResult> {
    const msalInstance = await getMsalInstance();

    const typeHint = getTypeHint(mailboxInfo);
    const correlationId = naaRequest.correlationId || getGuid();

    // check MSAL first to see if we have a logged in account for this MailboxInfo
    let msalAccount = getAccountFromMsal(msalInstance, mailboxInfo, true /*isNaa*/);

    if (!msalAccount) {
        // if we don't, attempt MSAL login for this account
        await tryLoginUser(
            msalInstance,
            typeHint,
            mailboxInfo,
            silent,
            correlationId,
            naaRequest.claims
        );

        msalAccount = getAccountFromMsal(msalInstance, mailboxInfo, true /*isNaa*/);
    }

    if (!msalAccount) {
        // tryLoginUser should have thrown an error, but in case it didn't and we still don't
        // have an account, bail out here because we need one beyond this point
        throw createClientAuthError(ClientAuthErrorCodes.noAccountFound);
    }

    const scopes = naaRequest.scope.split(' ').filter(s => !!s);
    const request: SilentRequest & PopupRequest = {
        ...getBrokerBaseRequestParameters(
            msalInstance,
            typeHint,
            mailboxInfo,
            trustedOrigin,
            naaRequest.clientId,
            scopes,
            msalAccount,
            correlationId,
            naaRequest.authority,
            naaRequest.claims
        ),
        account: msalAccount,
        popupWindowParent: targetWindow,
    };

    // call silent or popup, respecting what the child app requested (let's not try to be smart here
    // to avoid duplicate requests if the child app fails acquireTokenSilent the first time)
    const result = await (silent
        ? msalInstance.acquireTokenSilent(request)
        : msalInstance.acquireTokenPopup(request));

    const idTokenClaims: {
        aud?: string;
    } = result.idTokenClaims;

    // enforce idToken audience matches nested app clientId
    if (idTokenClaims.aud !== naaRequest.clientId) {
        throw createAuthError('client_id_aud_mismatch');
    }

    return result;
}

async function tryLoginUser(
    msalInstance: IPublicClientApplication,
    typeHint: AccountSourceType,
    mailboxInfo: MailboxInfo,
    silent: boolean,
    correlationId: string,
    claims?: string
): Promise<void> {
    // MSAL login flow for NAA should always attempt silent login first (ssoSilent) which
    // can succeed in many cases, and if it throws an `interaction_required` error, we try
    // loginPopup to remedy any credential issues host-side without redirects
    await loginUserMsalInternal(
        correlationId,
        msalInstance,
        InteractionType.Silent,
        typeHint,
        undefined /*msalAccount*/,
        mailboxInfo,
        undefined /*username*/,
        undefined /*resource*/,
        undefined /*scope*/,
        undefined /*promptValue*/,
        claims
    ).catch(error => {
        if (!silent && error instanceof InteractionRequiredAuthError) {
            return loginUserMsalInternal(
                correlationId,
                msalInstance,
                InteractionType.Popup,
                typeHint,
                undefined /*msalAccount*/,
                mailboxInfo,
                undefined /*username*/,
                undefined /*resource*/,
                undefined /*scope*/,
                undefined /*promptValue*/,
                claims
            );
        }

        return Promise.reject(error);
    });
}
