export function parseChallenges(header: string): {
    [key: string]: string | undefined;
} {
    const challengeMap: {
        [key: string]: string;
    } = {};
    const regex = /(\w+)="([^"]*)"/g;
    let match;
    while ((match = regex.exec(header)) !== null) {
        const key = match[1];
        const value = decodeURI(match[2]);
        challengeMap[key] = value;
    }
    return challengeMap;
}
