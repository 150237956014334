import { getOrigin } from 'owa-url/lib/getOrigin';
import { MsaTenantId } from 'owa-service/lib/constants/ApplicationId';
import { getRedirectUri } from '../initializeMsalLibrary';

// This will be used to clear the cookies from the current domain.
// Which is essentially loading the same logoutframe.html page with the clearCookiesOnly param set to true.
export async function clearCookies(tenantId: string) {
    let launch_url;
    if (tenantId == MsaTenantId) {
        // This is to make sure that the logout frame is loaded with the indexed path for consumer scenario.
        // Will avoid an extra 302 from /mail to /mail/0.
        launch_url = `${getOrigin()}/mail/0/logoutframe.html?iss=${MsaTenantId}&loadMoreIframes=false&clearCookiesOnly=true`;
    } else {
        launch_url = `${getRedirectUri()}logoutframe.html?loadMoreIframes=false&clearCookiesOnly=true`;
    }
    const iframe = self.document.createElement('iframe');

    // hidden iframe
    iframe.style.cssText = 'position: absolute; width:0; height:0; border:0;';
    iframe.id = 'iframe_cc';
    iframe.src = launch_url;

    self.document.body.appendChild(iframe);
}
