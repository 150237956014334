import type { MailboxInfo } from 'owa-client-types';
import { AccountSourceType } from 'owa-account-source-list-types';
import { getCoprincipalAccountForMailboxInfo } from 'owa-account-source-list-store';
import { isBusinessDomain } from './isMsalEnabledForBusiness';
import { isConsumerDomain } from './isMsalEnabledForConsumerDomain';
import providerToSource from 'owa-account-source-list-types/lib/utils/providerToSource';
import type { AccountInfo } from '@azure/msal-browser-1p';
import { EXCHANGE_CONSUMER_TENANT_ID, MICROSOFT_CONSUMER_TENANT_ID } from './utils/constants';

export function getTypeHint(mailboxInfo?: MailboxInfo): AccountSourceType {
    if (mailboxInfo) {
        return getTypeHintFromMailboxInfo(mailboxInfo);
    }

    // If mailbox info is not present, then we need to rely on the host origin url to distinguish between the consumer vs business login
    return getTypeHintFromDomain();
}

export function getTypeHintFromAccountInfo(account: AccountInfo): AccountSourceType {
    if ([MICROSOFT_CONSUMER_TENANT_ID, EXCHANGE_CONSUMER_TENANT_ID].includes(account.tenantId)) {
        return AccountSourceType.OutlookDotCom;
    } else {
        return AccountSourceType.Office365;
    }
}

export function getTypeHintFromMailboxInfo(mailboxInfo: MailboxInfo): AccountSourceType {
    if (!!mailboxInfo.mailboxProvider) {
        // If the mailbox provider is present we can use it to determine the account source type
        return providerToSource(mailboxInfo.mailboxProvider);
    }

    const accountSource = getCoprincipalAccountForMailboxInfo(mailboxInfo);
    return accountSource?.sourceType ?? AccountSourceType.Other;
}

export function getTypeHintFromDomain(): AccountSourceType {
    if (isBusinessDomain()) {
        return AccountSourceType.Office365;
    } else if (isConsumerDomain()) {
        return AccountSourceType.OutlookDotCom;
    }

    return AccountSourceType.Other;
}
