import type { BridgeError } from './msal-types/BridgeError';
import { BridgeStatusCode } from './msal-types/BridgeStatusCode';
import createBridgeError from './createBridgeError';

export function buildUnexpectedBridgeError(
    subError: string,
    description: string,
    properties: object = {}
): BridgeError {
    return createBridgeError(
        BridgeStatusCode.PersistentError,
        'unexpected_bridge_error',
        subError,
        description,
        properties
    );
}
