import type { HeadersWithoutIterator } from 'owa-service/lib/RequestOptions';
import { parseChallenges } from './parseChallenges';

export default function getClaims(headers: HeadersWithoutIterator): string | undefined {
    const authenticateHeader = headers.get('WWW-Authenticate');
    const claimsChallenge = authenticateHeader
        ? parseChallenges(authenticateHeader).claims
        : undefined;

    if (claimsChallenge) {
        try {
            return atob(claimsChallenge);
        } catch {
            // noop
        }
    }

    return undefined;
}
