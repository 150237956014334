import type { NestedAppAuthExecutor } from './NestedAppAuthExecutor';
import { processNaaBridgeMessage } from './processNaaBridgeMessage';
import { isBridgeRequestEnvelope } from './msal-types/BridgeRequestEnvelope';
import { logStartCoreUsage } from 'owa-analytics-start';

/**
 * Determines if request is a Nested App Auth request, and if it is it will process
 * the request using the specified executor
 * @param request NAA request payload; `BridgeRequestEnvelope` serialized as JSON
 * @param executor Executor for Nested App Auth
 * @param origin Origin URL of the app making the request
 * @returns Promise that resolves a string; `BridgeResponseEnvelope` serialized as JSON
 */
export async function executeNaaRequest(
    request: string,
    executor: NestedAppAuthExecutor,
    origin: string,
    isTrustedOrigin: boolean,
    targetWindow: Window,
    scenarioName: string,
    callerId: string
): Promise<string> {
    const { host, protocol } = new URL(origin);

    if (protocol !== 'https:' || !host) {
        throw new Error('Invalid origin');
    }

    // parse the request. If this fails, it'll throw and reject this promise, which is all we can do
    // without a valid requestId to construct a NAA error.
    const bridgeRequest = JSON.parse(request);

    if (
        typeof bridgeRequest !== 'object' ||
        bridgeRequest === null ||
        !isBridgeRequestEnvelope(bridgeRequest)
    ) {
        throw new Error('Invalid request payload');
    }

    const bridgeResponse = await processNaaBridgeMessage(
        bridgeRequest,
        executor,
        host,
        isTrustedOrigin,
        targetWindow
    );

    logStartCoreUsage('NAARequest', {
        scenarioName,
        callerId,
        method: bridgeRequest.method,
        success: bridgeResponse.success,
        duration: bridgeRequest.sendTime ? Date.now() - bridgeRequest.sendTime : undefined,
        status: bridgeResponse.error?.status,
        code: bridgeResponse.error?.code,
        subError: bridgeResponse.error?.subError,
        correlationId: bridgeRequest.tokenParams?.correlationId,
        isTrustedOrigin,
    });

    return JSON.stringify(bridgeResponse);
}
