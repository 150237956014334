import signoutFromMsal from './signoutFromMsal';

export default async function onActivityTimeoutErrorForMSAL(): Promise<void> {
    // TODO: Add some logging
    await signoutAndRedirect();
}
const signoutUrl = '/owa/logoff.owa';

async function signoutAndRedirect(): Promise<void> {
    await signoutFromMsal();

    // fallback to old logoff page since we do a local logout
    // with MSAL currently and dont redirect users.
    location.assign(signoutUrl);

    return Promise.resolve();
}
