import type { BridgeError } from './msal-types/BridgeError';
import type { BridgeResponseEnvelope } from './msal-types/BridgeResponseEnvelope';

export type NestedAppAuthOptionalKeys = Pick<
    BridgeResponseEnvelope,
    'token' | 'account' | 'initContext'
>;

/**
 * Builds a successful BridgeResponseEnvelope based on the supplied response
 * @param requestId NAA request ID
 * @param response One or more of the optional props on the `BridgeResponseEnvelope`
 * @returns
 */
export function buildSuccessfulBridgeResponseEnvelope(
    requestId: string,
    response: NestedAppAuthOptionalKeys
): BridgeResponseEnvelope {
    return {
        messageType: 'NestedAppAuthResponse',
        requestId,
        success: true,
        ...response,
    };
}

/**
 * Builds a failed BridgeResponseEnvelope based on the supplied error
 * @param requestId NAA request ID
 * @param error A `BridgeError` describing the error
 * @returns
 */
export function buildFailedBridgeResponseEnvelope(
    requestId: string,
    error: BridgeError
): BridgeResponseEnvelope {
    return {
        messageType: 'NestedAppAuthResponse',
        requestId,
        success: false,
        error,
    };
}
