import type { BridgeResponseEnvelope } from './msal-types/BridgeResponseEnvelope';
import {
    type NestedAppAuthOptionalKeys,
    buildSuccessfulBridgeResponseEnvelope,
    buildFailedBridgeResponseEnvelope,
} from './buildBridgeResponseEnvelope';
import { buildUnexpectedBridgeError } from './buildUnexpectedBridgeError';
import { isBridgeError } from './msal-types/BridgeError';

export async function processNestedAppAuthResponse(
    requestId: string,
    responsePromise: Promise<NestedAppAuthOptionalKeys>
): Promise<BridgeResponseEnvelope> {
    try {
        const result = await responsePromise;

        if (!result.token && !result.initContext) {
            throw buildUnexpectedBridgeError(
                'invalid_response',
                'The bridge response did not contain a token or initContext'
            );
        }

        return buildSuccessfulBridgeResponseEnvelope(requestId, result);
    } catch (err) {
        const error =
            err && isBridgeError(err)
                ? err
                : buildUnexpectedBridgeError('unknown_error', String(err));
        return buildFailedBridgeResponseEnvelope(requestId, error);
    }
}
