import { type MailboxInfo, getIndexerValueForMailboxInfo } from 'owa-client-types';
import type { NestedAppAuthExecutor } from './NestedAppAuthExecutor';
import type { NestedAppAuthExecutorFactory } from './NestedAppAuthExecutorFactory';
import { executeNaaRequest } from './executeNaaRequest';

const executors = new Map<string, NestedAppAuthExecutor>();

function getNestedAppAuthExecutorFromCache(
    executorFactory: NestedAppAuthExecutorFactory,
    mailboxInfo: MailboxInfo
): NestedAppAuthExecutor | undefined {
    const key = getIndexerValueForMailboxInfo(mailboxInfo);

    // find existing executor for this account
    let executor = executors.get(key);

    if (executor) {
        return executor;
    }

    // create a new executor using the provided factory if we haven't already
    executor = executorFactory(mailboxInfo);

    if (executor) {
        executors.set(key, executor);
    }

    return executor;
}

/**
 * Determines if request is a Nested App Auth request, and if it is it will process
 * the request using the specified executor
 * @param mailboxInfo The MailboxInfo for the account for which the request is to be executed
 * @param request NAA request payload; `BridgeRequestEnvelope` serialized as JSON
 * @param origin Origin URL of the app making the request
 * @returns Promise that resolves a string; `BridgeResponseEnvelope` serialized as JSON
 */
export function executeNaaRequestForMailboxInfo(
    executorFactory: NestedAppAuthExecutorFactory,
    mailboxInfo: MailboxInfo,
    request: string,
    origin: string,
    isTrustedOrigin: boolean,
    targetWindow: Window,
    scenarioName: string,
    callerId: string
): Promise<string> {
    const executor = getNestedAppAuthExecutorFromCache(executorFactory, mailboxInfo);

    if (executor) {
        return executeNaaRequest(
            request,
            executor,
            origin,
            isTrustedOrigin,
            targetWindow,
            scenarioName,
            callerId
        );
    }

    throw new Error('Unable to get NestedAppAuthExecutor');
}
