import type { AuthenticationResult } from '@azure/msal-browser-1p';
import { AccountSourceType } from 'owa-account-source-list-types';
import type TokenResponse from 'owa-service/lib/contract/TokenResponse';
import type TokenResponseCode from 'owa-service/lib/contract/TokenResponseCode';

export function msalAuthenticationResultToTokenResponse(
    authResult: AuthenticationResult,
    typeHint: AccountSourceType
): TokenResponse {
    const token = msalAuthenticationResultToAuthorizationHeader(authResult, typeHint);
    const resultCode = token ? 0 : 2;

    return {
        AccessToken: token,
        TokenResultCode: resultCode,
        ExpiresIn: authResult.expiresOn
            ? (authResult.expiresOn.getTime() - Date.now()) / 1000
            : undefined,
        AccessTokenExpiry: authResult.expiresOn?.toUTCString(),
    };
}

export function msalAuthenticationResultToAuthorizationHeader(
    authResult: AuthenticationResult,
    typeHint: AccountSourceType
): string {
    if (typeHint === AccountSourceType.OutlookDotCom) {
        const isMSAFullTrustCompactTicket = authResult.scopes[0]
            ?.toLocaleLowerCase()
            .startsWith('service::');

        if (isMSAFullTrustCompactTicket) {
            return `MSAuth1.0 usertoken="${authResult.accessToken}", type="MSACT"`;
        }
    }

    return `Bearer ${authResult.accessToken}`;
}
