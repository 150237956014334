import type { BridgeError } from './msal-types/BridgeError';
import type { BridgeStatusCode } from './msal-types/BridgeStatusCode';

export default function createBridgeError(
    status: BridgeStatusCode,
    code: string,
    subError?: string,
    description?: string,
    properties: object = {}
): BridgeError {
    return {
        status,
        code,
        subError,
        description,
        properties,
    };
}
