import type { AccountInfo } from '@azure/msal-browser-1p';
import type { MailboxInfo } from 'owa-client-types';
import { getItem } from 'owa-local-storage';
import { getAccountScopeUserSettings } from 'owa-session-store/lib/selectors/getAccountScopeUserSettings';

export function getLoginHint(
    msalAccount?: AccountInfo,
    mailboxInfo?: MailboxInfo,
    username?: string
): string | undefined {
    if (msalAccount) {
        return msalAccount.idTokenClaims?.login_hint || msalAccount.username;
    } else if (mailboxInfo) {
        const sessionData = getAccountScopeUserSettings(mailboxInfo).SessionSettings;
        return sessionData?.LoginHint ?? sessionData?.UserPrincipalName ?? mailboxInfo.userIdentity;
    } else if (username) {
        return username;
    }

    // As OWA switches to storing MSAL artifacts in session storage over local storage,
    // login_hint must be stored in a persistent storage to be used across tabs to support SSO instead of prompting users to the login page consistently.
    const currentActiveLoginHint = getItem(self, 'login_hint');
    if (currentActiveLoginHint) {
        return currentActiveLoginHint;
    }

    return undefined;
}
