import type { AccountInfo } from '@azure/msal-browser-1p';
import { MsaTenantId } from 'owa-service/lib/constants/ApplicationId';
import {
    POST_LOGOUT_REDIRECT_URI_ENTERPRISE,
    POST_LOGOUT_REDIRECT_URI_SHARED_ABT,
    OWA_BASE_PATH,
    POST_LOGOUT_REDIRECT_URI_CONSUMER_SUFFIX,
} from './constants';
import { findMetatag } from 'owa-metatags';
import { isUrlPresent } from 'owa-config';
import { getWindowData } from 'owa-window-data';

export function getPostLogoutRedirectUri(userAccount: AccountInfo, isSharedABT?: boolean) {
    if (!!isSharedABT) {
        const publicUrl = (findMetatag('publicUrl') || '').toLowerCase();
        const resource = isUrlPresent(publicUrl) ? publicUrl : OWA_BASE_PATH;
        return `${resource}${POST_LOGOUT_REDIRECT_URI_SHARED_ABT}`;
    } else if (userAccount?.tenantId == MsaTenantId) {
        return `${getWindowData().location.origin}${POST_LOGOUT_REDIRECT_URI_CONSUMER_SUFFIX}`;
    } else {
        return POST_LOGOUT_REDIRECT_URI_ENTERPRISE;
    }
}
